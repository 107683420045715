import React, { useState, useLayoutEffect, useRef } from 'react';
import { Link } from "react-scroll";
import "./About.scss";
import about1 from "../../resources/images/about-img-1.webp"
import about2 from "../../resources/images/about-img-2.webp"

function About() {

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
        offsetHeight: -Math.abs(targetRef.current.offsetHeight) + 1 * window.innerHeight / 90,
      });
    }
  }, []);

  return (
    <div className='about-container' id='about'>


      <div className='about-text-container'>
        <h3 className='about-sub-title'>¿Quiénes Somos?</h3>
        <h2 className='about-title'>¿Qué es la <br></br>Medicina <span className='about-title-color'>Estética</span>?</h2>

        <h3 className='about-textbox'>La medicina estética es un campo médico que combina la ciencia y la belleza para ofrecer soluciones no quirúrgicas destinadas a mejorar la apariencia física y el bienestar de los pacientes. Desde tratamientos para la piel hasta procedimientos para reducir arrugas y remodelar el cuerpo, la medicina estética se centra en realzar la belleza natural y abordar preocupaciones estéticas sin recurrir a cirugías invasivas.</h3>
        <h3 className='about-textbox'>Nosotros somos líderes en formación para médicos estéticos, ofreciendo cursos de vanguardia para iniciarte en la medicina estética o potenciar tu práctica actual.</h3>
        <Link className='about-button' to="contactform" spy={true} smooth={true} offset={dimensions.offsetHeight - 100} duration={1500}>Comienza Ya</Link>
      </div>

      <div className='about-photos-container'>
        <img className='about-photo-1' src={about1} alt='Cargando...'></img>
        <img className='about-photo-2' src={about2} alt='Cargando...'></img>
      </div>


    </div>
  );
}

export default About;