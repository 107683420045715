import React, { useState } from 'react';
import "./Courses.scss";
import sculptra from "../../resources/images/Sculptra.webp"
import ellanse from "../../resources/images/Ellanse.webp"
import corpusenzimas from "../../resources/images/CorpusEnzimas.webp"
import conde from "../../resources/images/Conde.webp"

function Courses() {

  const [videos, setVideos] = useState([
    {
      title: 'Taller Galderma | Sculptra',
      thumbnail: sculptra,
      videoUrl: 'https://tube.rvere.com/embed?v=ncZY9N2w5pk'
    },
    {
      title: 'Taller Ellansé',
      thumbnail: ellanse,
      videoUrl: 'https://www.youtube.com/embed/x7IvM-JJgFQ?si=_q0gjaCeb5UuUz5f'
    },
    {
      title: 'Congreso 28° de la Universidad del Conde | Corpus Bella',
      thumbnail: conde,
      videoUrl: 'https://tube.rvere.com/embed?v=EIvVG_1uxM8'
    },
    {
      title: 'Enzimas Corpus Bella',
      thumbnail: corpusenzimas,
      videoUrl: 'https://tube.rvere.com/embed?v=mVziQ_3QFkc'
    }
    // Add more videos here with their thumbnails and URLs
  ]);
  const [activeVideo, setActiveVideo] = useState(null);

  const handleThumbnailClick = (videoUrl) => {
    setActiveVideo(videoUrl);
  };

  return (
    <div className='courses-container' id='courses'>
    <div className='courses-text-container'>
      <h3 className='courses-sub-title'>Nuestra Educación</h3>
      <h3 className='courses-title'>Nuestros  <span className='courses-title-color'>Talleres</span></h3>
    </div>

    {videos.map((video, index) => (
      <div className='courses-single-container' key={index}>
        <h2 className='courses-single-title'>{video.title}</h2>
        {!activeVideo || activeVideo !== video.videoUrl ? (
          <img
            className='courses-single-iframe'
            src={video.thumbnail}
            alt={video.title}
            onClick={() => handleThumbnailClick(video.videoUrl)}
          />
        ) : (
          <iframe
            className='courses-single-iframe'
            src={video.videoUrl}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        )}
      </div>
    ))}
  </div>
  );
}

export default Courses;