import React from 'react';
import "./Values.scss";
import values from "../../resources/images/values-img.webp"
import valuesIcon1 from "../../resources/images/values-icon-1.webp"
import valuesIcon2 from "../../resources/images/values-icon-2.webp"
import valuesIcon3 from "../../resources/images/values-icon-3.webp"
import valuesIcon4 from "../../resources/images/values-icon-4.webp"


function Values() {

  return (
    <div className='values-container' id='values'>
      <div className='values-text-container'>
        <h3 className='values-sub-title'>Nuestra Filosofía</h3>
        <h3 className='values-title'>Nuestros <span className='values-title-color'>Valores</span></h3>

        <h3 className='values-textbox'>Profesionalismo, educación, excelencia e innovación, estos valores son nuestra brújula en todo lo que hacemos. Nos esforzamos por ser éticos y respetuosos, garantizando la seguridad y el bienestar de nuestros pacientes. Buscamos constantemente aprender y enseñar, impulsando la formación continua y actualizada en medicina estética.</h3>
                                        
        <div className='values-icons-general-container'>
          <div className='values-icons-container'>

            <div className='value-single-icon-container'>
              <img className="value-icon" src={valuesIcon1} alt='Cargando...'></img>
              <h3 className='value-icon-text'>Profesionalismo</h3>
            </div>

            <div className='value-single-icon-container'>
              <img className="value-icon" src={valuesIcon2} alt='Cargando...'></img>
              <h3 className='value-icon-text'>Excelencia</h3>
            </div>


          </div>

          <div className='values-icons-container'>

            <div className='value-single-icon-container'>
              <img className="value-icon" src={valuesIcon3} alt='Cargando...'></img>
              <h3 className='value-icon-text'>Innovación</h3>
            </div>

            <div className='value-single-icon-container'>
              <img className="value-icon" src={valuesIcon4} alt='Cargando...'></img>
              <h3 className='value-icon-text'>Educación</h3>
            </div>

          </div>
        </div>


      </div>

      <div className='values-img-container'>
        <img className='values-img' src={values} alt='Cargando...'></img>
      </div>
    </div>
  );
}

export default Values;