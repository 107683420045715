import './App.css';
import Nav from './components/Nav/Nav';
import Home from './components/Home/Home';
import ButtonSeparator from './components/ButtonSeparator/ButtonSeparator';
import About from './components/About/About';
import Courses from './components/Courses/Courses';
import InfoSeparator from './components/InfoSeparator/InfoSeparator';
import Values from './components/Values/Values';
import ContactForm from './components/ContactForm/ContactForm';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Nav></Nav>
      <Home></Home>
      <ContactForm></ContactForm>
      {/* <ButtonSeparator></ButtonSeparator> */}
      <Courses></Courses>
      {/* <InfoSeparator></InfoSeparator> */}
      <About></About>
      <Values></Values>
      <Footer></Footer>
    </div>
  );
}

export default App;
