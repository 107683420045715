import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './ContactForm.scss';
import { gtag } from '../../utils/analytics'; // Import gtag function

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    workshop: ' Hilos White Medience Rinomodelación', // Default workshop
  });

  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Clear validation error when the user starts typing
    setErrors({
      ...errors,
      [e.target.name]: null,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = 'Nombre Completo es requerido';
    }

    // Validate email
    if (!formData.email.trim()) {
      newErrors.email = 'Correo Electrónico es requerido';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Correo Electrónico no válido';
    }

    // Validate phone
    if (!formData.phone.trim()) {
      newErrors.phone = 'Numero Telefónico es requerido';
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = 'Numero Telefónico debe contener solo números';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form before submitting
    if (validateForm()) {
      // Replace these values with your own Email.js template parameters
      const templateParams = {
        from_name: formData.name,
        from_email: 'ilmem2848@gmail.com',
        customer_email: formData.email,
        phone: formData.phone,
        workshop: formData.workshop,
      };

      // Replace these values with your own Email.js service ID, template ID, and user ID
      emailjs
        .send(
          'service_ibsabuw',
          'template_s4ih3qq',
          templateParams,
          'GuX5PsY2W3KxkLUGv'
        )
        .then((response) => {
          console.log('Email sent successfully:', response);

          // Track conversion event
          gtag('conversion', {
            send_to: 'AW-16453826556/QkA6CJOzyZkZEPzv5aU9',
          });

          setIsModalOpen(true);
        })
        .catch((error) => {
          console.error('Error sending email:', error);
        });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='form-container' id='contactform'>
      <div className='form-text-container'>
        <h3 className='form-sub-title'>Expande tu conocimiento</h3>
        <h2 className='form-title'>
          Inscríbete <span className='form-title-color'>a un taller</span>
        </h2>
      </div>

      <form className='form-tag-container' onSubmit={handleSubmit}>
        <div className='form-input-container'>
          <label className='form-input-label'>Nombre Completo</label>
          <input
            className='form-input'
            name='name'
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <p className='error-message'>{errors.name}</p>}
        </div>

        <div className='form-input-container'>
          <label className='form-input-label'>Correo Electrónico</label>
          <input
            className='form-input'
            name='email'
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <p className='error-message'>{errors.email}</p>}
        </div>

        <div className='form-input-container'>
          <label className='form-input-label'>Numero Telefónico</label>
          <input
            className='form-input'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
          />
          {errors.phone && <p className='error-message'>{errors.phone}</p>}
        </div>

        <div className='form-input-container'>
          <label className='form-input-label'>¿Qué taller te interesa?</label>
          <select
            className='form-input'
            name='workshop'
            value={formData.workshop}
            onChange={handleChange}
          >
            <option>Full Face</option>
            <option>Enzimas Corpus Bella </option>
            <option>Sculptra + Restylane Skinbooster </option>
            <option>Hilos White Medience</option>
            <option>Xeomeen</option>
          </select>
        </div>

        <button className='form-button' type='submit'>
          ¡Inscríbete!
        </button>

        <div className="separator">
          <div className='separator-line'></div>
          <span className="separator-text">ó</span>
          <div className='separator-line'></div>
        </div>

        <a
          href='https://wa.me/+5215538936640'
          className='wpp-button'
          target='_blank'
          rel='noreferrer'
          onClick={() =>
            gtag('conversion', {
              send_to: 'AW-16453826556/QkA6CJOzyZkZEPzv5aU9',
            })
          }
        >
          <FontAwesomeIcon className='wpp-icon' icon={faWhatsapp}></FontAwesomeIcon>
          ¡Inscríbete vía WhatsApp!
        </a>
      </form>

      {/* Modal for success message */}
      {isModalOpen && (
        <div className='modal-overlay'>
          <div className='modal'>
            <p className='modal-message'>
              ¡Su solicitud se envió correctamente, lo contactaremos a la brevedad!
            </p>
            <FontAwesomeIcon className='modal-icon' icon={faCheckCircle}></FontAwesomeIcon>
            <button className='modal-close-button' onClick={closeModal}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactForm;
