import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import "./Nav.scss";
import { Link } from "react-scroll";

import logo from "../../resources/images/logo.webp"

function Nav() {

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });
    const [isMobile, setIsMobile] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useLayoutEffect(() => {
        if (targetRef.current) {
          setDimensions({
            width: targetRef.current.offsetWidth,
            height: targetRef.current.offsetHeight,
            offsetHeight: -Math.abs(targetRef.current.offsetHeight) + 1 * window.innerHeight / 90,
          });
        }
      }, []);


     // Function to update isMobile state based on screen width
  const updateIsMobile = () => {
    if (window.innerWidth <= 768) { // Adjust the breakpoint as needed
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    // Update isMobile state on component mount and window resize
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  // Add a state to track whether the navbar should be colored or not
  const [isNavColored, setIsNavColored] = useState(false);

  // Function to update the navbar color based on scroll position
  const handleScroll = () => {
    if (window.scrollY > targetRef.current.offsetTop) {
      setIsNavColored(true);
    } else {
      setIsNavColored(false);
    }
  };

  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav ref={targetRef} className={`nav-container ${isNavColored ? 'colored-nav' : ''}`}>
      {isMobile ? (
        <div className="mobile-menu">
          <button className="mobile-menu-button" onClick={toggleDropdown}>&#9776;</button>
          {isDropdownOpen && (
            <div className="mobile-dropdown">
              <Link onClick={toggleDropdown} className='mobile-dropdown-link' to="home" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Inicio</Link>
              <Link onClick={toggleDropdown} className='mobile-dropdown-link' to="contactform" spy={true} smooth={true} offset={dimensions.offsetHeight - 15} duration={1500}>Inscribete</Link>
              <Link onClick={toggleDropdown} className='mobile-dropdown-link' to="courses" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Nuestros Talleres</Link>
              <Link onClick={toggleDropdown} className='mobile-dropdown-link' to="about" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>¿Quiénes Somos?</Link>
              <Link onClick={toggleDropdown} className='mobile-dropdown-link' to="values" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Nuestros Valores</Link>
              <Link onClick={toggleDropdown} className='mobile-dropdown-link' to="footer" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Contacto</Link>
            </div>
          )}
          <Link className='mobile-menu-title-container'
          to="home" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>
            <img className='mobile-menu-img' src={logo} alt='Cargando...'></img>
          </Link>
        </div>
      ) : (
        <div className="nav-links-container">

          <Link 
          className='desktop-menu-title-container'
          to="home" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}
          >
            <img className='desktop-menu-img' src={logo} alt='Cargando...'></img>
          </Link>

          <div className='desktop-nav-links-container'>
            <Link className='nav-single-link' onClick={updateIsMobile} to="home" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Inicio</Link>
            <Link className='nav-single-link' to="contactform" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Inscribete</Link>
            <Link className='nav-single-link' to="courses" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Nuestros Talleres</Link>
            <Link className='nav-single-link' to="about" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>¿Quiénes Somos?</Link>
            <Link className='nav-single-link' to="values" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Nuestros Valores</Link>
            <Link className='nav-single-link' to="footer" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Contacto</Link>
          </div>

        </div>
      )}
    </nav>
  );
}

export default Nav;
