import React, { useRef, useLayoutEffect, useState } from 'react';
import { Link } from "react-scroll";
import "./Footer.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faWhatsapp, faTiktok } from '@fortawesome/free-brands-svg-icons'
import logo from '../../resources/images/logo.webp'
import bluevista from '../../resources/images/BlueVista.webp'

function Footer() {

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
        offsetHeight: -Math.abs(targetRef.current.offsetHeight) + 1 * window.innerHeight / 90,
      });
    }
  }, []);

  return (
    <div className='footer-container' id='footer'>
      <div className='footer-text-container'>
        <img className='footer-logo' src={logo} alt='Cargando...'></img>
        <h3 className='footer-textbox'>Aspiramos a la excelencia en cada procedimiento, cada interacción y cada clase que ofrecemos, estamos dedicados a la innovación, siempre explorando nuevas técnicas y tecnologías para ofrecerte la mejor capacitación. </h3>
        <h3 className='footer-textbox'> A través de programas de formación rigurosos y actualizados, se busca empoderar a los profesionales con las habilidades y los conocimientos necesarios para brindar un servicio de calidad y estar a la vanguardia de los avances en el campo.</h3>

        <h3 className='footer-socials-title'>¡Síguenos en nuestras redes!</h3>
        <div className='footer-socials-container'>

          <FontAwesomeIcon className="contact-single-icon" icon={faInstagram}></FontAwesomeIcon>

          <a className="contact-icon-container" href="https://www.facebook.com/ILMEA.mx" target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="contact-single-icon" icon={faFacebook}></FontAwesomeIcon>
          </a>

          <a className="contact-single-icon-container" href="https://wa.me/+5215582640807" target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="contact-single-icon" icon={faWhatsapp}></FontAwesomeIcon>
          </a>

          <a className="contact-single-icon-container" href="https://www.tiktok.com/@colegio.ilmem" target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="contact-single-icon" icon={faTiktok}></FontAwesomeIcon>
          </a>

        </div>
      </div>

      <div className='footer-sitemap-container'>

        <div className='footer-sitemap'>
          <h3 className='footer-sitemap-title'>Mapa del Sitio</h3>
          <Link className='footer-sitemap-item' to="home" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Inicio</Link>
          <Link className='footer-sitemap-item' to="about" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>¿Quiénes Somos?</Link>
          <Link className='footer-sitemap-item' to="courses" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Nuestros Talleres</Link>
          <Link className='footer-sitemap-item' to="values" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Nuestros Valores</Link>
          <Link className='footer-sitemap-item' to="contactform" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Inscribete</Link>
          <Link className='footer-sitemap-item' to="footer" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Contacto</Link>

        </div>

        <h3 className='footer-iframe-title'>Encuéntranos en</h3>
        <iframe
          title='locationIframe'
          className='footer-iframe'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d279.7173648361771!2d-99.17398847511114!3d19.39454192966333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff718580b239%3A0x151b68053274e435!2sWTC!5e0!3m2!1ses!2smx!4v1705329481591!5m2!1ses!2smx"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade">
        </iframe>

      </div>

      <div className="footer-bottom-container">
        <a className="footer-bottom-name" href='https://www.facebook.com/profile.php?id=61554416925322' target="_blank" rel="noreferrer">Sitio web desarrollado por: &nbsp; <img className="footer-bottom-name-img" src={bluevista} alt="Cargando..."></img> Blue Vista</a>

        <div className="footer-bottom-social-container">
          <a href='https://wa.me/+5217224137774' target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="footer-bottom-icon" icon={faWhatsapp}></FontAwesomeIcon>
          </a>

          <a href='https://www.instagram.com/bluevistaglobal/' target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="footer-bottom-icon" icon={faInstagram}></FontAwesomeIcon>
          </a>

          <a href='https://www.facebook.com/profile.php?id=61554416925322' target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="footer-bottom-icon" icon={faFacebook}></FontAwesomeIcon>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;