import React from 'react';
import "./ButtonSeparator.scss";

function ButtonSeparator() {

  return (
    <>
    <div>sample</div>
    </>
  );
}

export default ButtonSeparator;
