import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import "./Home.scss";
import videoMobile from "../../resources/videos/video-web-ilmem.mp4"
import videoDesktop from "../../resources/videos/video-web-ilmem-desktop.mp4"
import { Link } from "react-scroll";

function Home() {

  const [isMobile, setIsMobile] = useState(false);
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
        offsetHeight: -Math.abs(targetRef.current.offsetHeight) + 1 * window.innerHeight / 90,
      });
    }
  }, []);

  const updateIsMobile = () => {
    if (window.innerWidth <= 768) { // Adjust the breakpoint as needed
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };


  useEffect(() => {
    // Update isMobile state on component mount and window resize
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return (
    <div className='home-container' id='home'>

      {
        isMobile ? (
          <video autoPlay muted loop playsInline id="video-background">
            <source src={videoMobile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <video autoPlay muted loop playsInline id="video-background-desktop">
            <source src={videoDesktop} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )
      }

      <div className='home-content'>
        <h1 className='home-title'> Educación<br></br> <span className='home-title-color'>Médico</span> <br></br> Estética</h1>
        <h2 className='home-sub-title'>Instituto Latinoamericano de <br></br>Medicina Estética Moderna</h2>
        <div className='home-buttons-container'>
        <Link className='home-button' to="contactform" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Inscribete a un taller</Link>
          <Link className='home-button' to="about" spy={true} smooth={true} offset={dimensions.offsetHeight - 50} duration={1500}>Conoce nuestra institución</Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
