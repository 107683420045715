import React from 'react';
import "./InfoSeparator.scss";

function InfoSeparator() {

  return (
    <div className="info-container">
      <h3 className="info-quote">"No se trata de donde vienes, <span className='info-quote-color'> sino de a donde vas"</span></h3>
      <div className="info-text-container">
        <h3 className="info-text">Para mas información acerca de nuestros talleres, cursos y diplomados haz clic aquí:</h3>
        <a href='https://www.facebook.com/ILMEA.mx' className="info-text-a" target="_blank" rel="noopener noreferrer">Conoce Mas &rarr;</a>
      </div>
    </div>
  );
}

export default InfoSeparator;